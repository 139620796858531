import React from "react";
import styled from 'styled-components';
import { motion } from 'framer-motion';
import Banner from '../../assets/sobre.jpg';
import BannerMobile from '../../assets/sobre_mobile.jpg';
import Banner1 from '../../assets/BannerEduardo.png';
import Banner2 from '../../assets/BannerVick.png';
import Banner3 from '../../assets/BannerAmanda.png';
import Banner4 from '../../assets/BannerGabi.png';
import Banner5 from '../../assets/BannerLuiz.png';
import Facebook from '../../assets/icon/facebook_white.png';
import Instagram from '../../assets/icon/instagram_white.png';
import Youtube from '../../assets/icon/youtube.png';

import Canal1 from '../../assets/canais/foto01.png';
import Canal2 from '../../assets/canais/foto02.png';
import Canal3 from '../../assets/canais/foto03.png';
import Canal4 from '../../assets/canais/foto04.png';
import Canal5 from '../../assets/canais/foto05.png';
import Canal6 from '../../assets/canais/foto06.png';
import Canal7 from '../../assets/canais/foto07.png';
import Toponame  from "../NameTopo/index.js";
import Menu from "../Menu/Menu.js";
import Footer from "../Footer/Footer.js";


const Container = styled.div`
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 1rem;
  background: white;
`;

const Imagem = styled.img`
  width: 100%;

  @media (max-width: 1000px){
        display: none;
    }
`;

const ImagemMobile = styled.img`
  width: 100%;
  display: none;

  @media (max-width: 1000px){
        display: block;
    }
`;

const Division =styled.div`
  width: 2px;
  height: 100px;
  background: black;
  margin: 10px;
`;

const ControllText = styled.div`
  width: 100%;
  max-width: 800px;
  `;

const Title = styled.h1`
   font-size: 2rem;
  font-weight: 600;
`;

const SubTitle = styled.p`
  color: gray;
  font-size: 1.3rem;
  font-weight: 400;
`;

const CardControll = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  flex-direction: row;

  @media (max-width: 1000px){
    flex-direction: column;
    }
`;

const Redes = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  a{
    width: 100%;
  }
`;

const Linkzada = styled.a`
  text-decoration: none;
  color: black;
`;

const Card2 = styled.div`
  margin: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1.5rem;
  border-radius: 10px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  transition: 300ms;
  cursor: pointer;

&:hover{
  transform: scale(1.1);
}

`;


const CardTitle2 = styled.h1`
  font-size: 1rem;
`;

const CardSub2 = styled.p`
   font-size: 1rem;
   color: gray;
   margin: 0;
`;


const CardImg2 = styled.img`
  max-width: 100px;
  border-radius: 10px;
`;


const Card = styled.div`
  margin: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1.5rem;
  border-radius: 10px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  transition: 300ms;

&:hover{
  transform: scale(1.1);
}


`;

const CardImg = styled.img`
  width: 100%;
  max-width: 250px;
  border-radius: 10px;
`;

const CardTitle = styled.h1`
  font-size: 1.5rem;
`;

const CardButton = styled.img`
  margin: 0.3rem;
  width: 30px;
  padding: 0.3rem;
  border-radius: 100px;
  border: none;
  cursor: pointer;
  background: black;
  color: white;
  font-size: 1rem;
  font-weight: 600;
  transition: 500ms;

&:hover{
  background: gray;
}

`;

function Sobre() {
    return (
    <div>
        <Toponame/>
        <Menu />
        <Imagem src={Banner} alt="Banner" title="Banner"/>
        <ImagemMobile src={BannerMobile}/>
        <Container>
  
          <ControllText>
            <Title>Sobre</Title>
            <SubTitle>O Espiritismo Raiz veio para ajudar, promovendo a essência do espiritismo e compartilhando saberes espirituais para enriquecer sua jornada de crescimento pessoal.</SubTitle>
          </ControllText>
          <Division></Division>
          <ControllText>
            <Title>Nossa Equipe</Title>
            <SubTitle>Conheça a equipe do Espiritismo Raiz, onde cada membro cria conteúdos incríveis para as redes sociais!</SubTitle>
         </ControllText>
          
          <CardControll>
              <Card>
                <CardImg src={Banner1} alt="Eduardo Sabbag" title="Eduardo Sabbag"/>
                <CardTitle>Eduardo Sabbag</CardTitle>
                <Redes>
                  <a target="_blank" href="https://www.instagram.com/espiritismo_raiz/"><CardButton src={Instagram}/></a>
                  <a target="_blank" href="https://www.facebook.com/eduardoespiritismo/?locale=pt_BR"><CardButton src={Facebook}/></a>
                  <a target="_blank" href="https://www.youtube.com/@EspiritismoRaiz"><CardButton src={Youtube}/></a>
                </Redes>
              </Card>

              <Card>
                <CardImg src={Banner2} alt="Vick" title="Vick"/>
                <CardTitle>Vick Raiz</CardTitle>
                <Redes>
                  <a target="_blank" href="https://www.instagram.com/vick_raiz/"><CardButton src={Instagram}/></a>
                  <a target="_blank" href="https://www.facebook.com/eduardoespiritismo/?locale=pt_BR"><CardButton src={Facebook}/></a>
                  <a target="_blank" href="https://www.youtube.com/@CanaldoEdu"><CardButton src={Youtube}/></a>
                </Redes>
              </Card>

              <Card>
                <CardImg src={Banner3} alt="Amanda" title="Amanda"/>
                <CardTitle>Amanda Raiz</CardTitle>
                <Redes>
                  <a target="_blank" href="https://www.instagram.com/amandairaiz/"><CardButton src={Instagram}/></a>
                  <a target="_blank" href="https://www.facebook.com/eduardoespiritismo/?locale=pt_BR"><CardButton src={Facebook}/></a>
                  <a target="_blank" href="https://www.youtube.com/@EspiritismoRaiz"><CardButton src={Youtube}/></a>
                </Redes>
              </Card>

              <Card>
                <CardImg src={Banner4} alt="Gabi" title="Gabi"/>
                <CardTitle>Gabi Raiz</CardTitle>
                <Redes>
                  <a target="_blank" href="https://www.instagram.com/gabi.espiritismoraiz/"><CardButton src={Instagram}/></a>
                  <a target="_blank" href="https://www.facebook.com/eduardoespiritismo/?locale=pt_BR"><CardButton src={Facebook}/></a>
                  <a target="_blank" href="https://www.youtube.com/@EspiritismoRaiz"><CardButton src={Youtube}/></a>
                </Redes>
              </Card>

              <Card>
                <CardImg src={Banner5} alt="Luiz" title="Luiz"/>
                <CardTitle>Luiz Raiz</CardTitle>
                <Redes>
                  <a target="_blank" href="https://www.instagram.com/luisespiristismo/"><CardButton src={Instagram}/></a>
                  <a target="_blank" href="https://www.facebook.com/eduardoespiritismo/?locale=pt_BR"><CardButton src={Facebook}/></a>
                  <a target="_blank" href="https://www.youtube.com/@EspiritismoRaiz"><CardButton src={Youtube}/></a>
                </Redes>
              </Card>
          </CardControll>
          <Division></Division>
          <ControllText>
            <Title>Canais</Title>
            <SubTitle>Inscreva-se em nossos canais para ter acesso a conteúdos enriquecedores!</SubTitle>
         </ControllText>


         <CardControll>
              <Linkzada target="_blank" href="https://www.youtube.com/@EspiritismoRaiz">
                <Card2>
                    <CardImg2 src={Canal1} alt="Canal YT" title="Canal YT"/>
                    <CardTitle2>Espiritismo Raiz</CardTitle2>
                    <CardSub2>Eduardo Sabbag</CardSub2>
                </Card2>
            </Linkzada>

            <Linkzada target="_blank" href="https://www.youtube.com/@CanaldoEdu">
              <Card2>
                  <CardImg2 src={Canal2} alt="Canal YT" title="Canal YT"/>
                  <CardTitle2>Espiritismo Raiz</CardTitle2>
                  <CardSub2>Estudos</CardSub2>
              </Card2>
           </Linkzada>

           <Linkzada target="_blank" href="https://www.youtube.com/@espiritismoraizenespanhol4966">
              <Card2>
                  <CardImg2 src={Canal3} alt="Canal YT" title="Canal YT"/>
                  <CardTitle2>Espiritismo Raiz</CardTitle2>
                  <CardSub2>Espanhol</CardSub2>
              </Card2>
           </Linkzada>

           <Linkzada target="_blank" href="https://www.youtube.com/@EspiritismoRaizKids">
              <Card2>
                  <CardImg2 src={Canal4} alt="Canal YT" title="Canal YT"/>
                  <CardTitle2>Espiritismo Raiz</CardTitle2>
                  <CardSub2>Kids</CardSub2>
              </Card2>
           </Linkzada>

           <Linkzada target="_blank" href="https://www.youtube.com/@espiritismokardec">
              <Card2>
                  <CardImg2 src={Canal5} alt="Canal YT" title="Canal YT"/>
                  <CardTitle2>Eduardo Sabbag</CardTitle2>
                  <CardSub2>Raiz</CardSub2>
              </Card2>
           </Linkzada>

           <Linkzada target="_blank" href="https://www.youtube.com/@Spiritismthemeaningoflife">
              <Card2>
                  <CardImg2 src={Canal6} alt="Canal YT" title="Canal YT"/>
                  <CardTitle2>Espiritismo Raiz</CardTitle2>
                  <CardSub2>Inglês</CardSub2>
              </Card2>
           </Linkzada>

           <Linkzada target="_blank" href="https://www.youtube.com/@EspiritualidadeRaiz">
              <Card2>
                  <CardImg2 src={Canal7} alt="Canal YT" title="Canal YT"/>
                  <CardTitle2>Espiritualidade Raiz</CardTitle2>
                  <CardSub2>Espiritismo Raiz</CardSub2>
              </Card2>
           </Linkzada>
          </CardControll>
        </Container>
        <Footer/>
        </div>
    );
  }
  
  export default Sobre;

