import React from "react";
import styled from 'styled-components';
import Facebook from '../../assets/icon/facebook_white.png';
import Instagram from '../../assets/icon/instagram_white.png';
import Youtube from '../../assets/icon/youtube.png';

const Container = styled.div`
  padding: 1rem;
  background: black;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  @media (max-width: 1000px){
        flex-direction: column;
    }
`;

const RedesSociais = styled.div`
  display: flex;
  flex-direction: row;
`;

const Imagem = styled.img`
  width: 40px;
  height: 40px;
`;

const SubTitle = styled.h1`
  text-align: center;
  color: white;
  font-weight: 600;
  font-size: 22px;
`;

const Copyright = styled.p`
  text-align: center;
  color: #c3c3c3;
`;

function Footer() {
    return (
      <>
      <Container>
         <SubTitle>REDES SOCIAIS</SubTitle>
         <RedesSociais> 
            <a target="_blank" href="https://www.instagram.com/espiritismo_raiz/">
                <Imagem src={Instagram} title="Instagram" alt="Instagram"/>
                
            </a>
            <a target="_blank" href="https://www.facebook.com/eduardoespiritismo">
                <Imagem src={Facebook} title="Facebook" alt="Facebook"/>
            </a>
            <a target="_blank" href="https://www.youtube.com/espiritismoraiz">
                <Imagem src={Youtube} title="Youtube" alt="Youtube"/>
            </a>
        </RedesSociais>
            <Copyright>©2023 Espiritismo Raiz. Todos os direitos reservados.</Copyright>
      </Container>
      </>
    );
  }
  
  export default Footer;

