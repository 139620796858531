import React from "react";
import styled from 'styled-components';
import Foto1 from '../../assets/icon/01.png';
import Icon1 from '../../assets/icon/boasenergias.png';
import Foto2 from '../../assets/icon/02.jpg';
import Icon2 from '../../assets/icon/coracao.png';


const Div = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  background: white;
`;


const Container = styled.div`
  display: flex;
  flex-direction: row;

  @media (max-width: 1000px){
        flex-direction: column;
    }
`;

const Imagem = styled.img`
  width: 50%;

  @media (max-width: 1000px){
    width: 100%;
    }
`;

const BoxCard = styled.div`
  width: 50%;
  display: block;
  flex-direction: column;
  justify-content: center;
  align-self: center;
  padding: 2rem;

  @media (max-width: 1000px){
    width: 300px;
    }
`;


const BoxCardEdit = styled.div`
  width: 50%;
  display: block;
  flex-direction: column;
  justify-content: center;
  align-self: center;
  padding: 2rem;

  @media (max-width: 1000px){
    width: 100%;
    display: none;
    }
`;


const BoxCardMobile = styled.div`
  width: 50%;
  display: none;
  flex-direction: column;
  justify-content: center;
  align-self: center;
  padding: 2rem;

  @media (max-width: 1000px){
    display: block;
    width: 300px;
    }
`;


const BoxTituloControll = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: -20px;
`;

const BoxImage = styled.img`
  width: 60px;
  margin-right: 8PX;

  @media (max-width: 1000px){
    width: 50px;
    }
`;

const BoxTitulo = styled.h1`
  font-size: 3rem;
  font-weight: 600;

  @media (max-width: 1000px){
    font-size: 2rem;
    }
`;

const BoxSub = styled.p`
  max-width: 900px;
  width: 100%;
  margin-left: 0.2rem;
  font-size: 1.5rem;
  font-weight: 400;

  @media (max-width: 1000px){
    font-size: 1rem;
    }
`;

const BoxButton = styled.button`
  width: 300px;
  padding: 1.5rem;
  border: none;
  border-radius: 10px;
  background: black;
  color: white;
  font-size: 1rem;
  font-weight: 600;
  cursor: pointer;
  transition: 500ms;

  &:hover{
    background: gray;
  }
`;

function Card() {
    return (
      <Div>
      <Container>
      <BoxCardMobile>
          <BoxTituloControll>
            <BoxImage src={Icon1} alt="icone" title="icone"/>
            <BoxTitulo>Energias</BoxTitulo>
          </BoxTituloControll>
          <BoxSub>Receba as boas energias dos bons espíritos e ilumine sua jornada.</BoxSub>
          <a href="/energias">
          <BoxButton>Clique aqui!</BoxButton>
          </a>
        </BoxCardMobile>

        <Imagem src={Foto1} alt="Energias" title="Energias"/>
        <BoxCardEdit>
          <BoxTituloControll>
            <BoxImage src={Icon1} alt="Energias Icone" title="Energias"/>
            <BoxTitulo>Energias</BoxTitulo>
          </BoxTituloControll>
          <BoxSub>Receba as boas energias dos bons espíritos e ilumine sua jornada.</BoxSub>
          <a href="/energias">
          <BoxButton alt="Botão">Clique aqui!</BoxButton>
          </a>
        </BoxCardEdit>
      </Container>

      <Container>
        <BoxCard>
          <BoxTituloControll>
            <BoxImage src={Icon2} alt="Caridade Icone" title="Caridade"/>
            <BoxTitulo>Caridade</BoxTitulo>
          </BoxTituloControll>
          <BoxSub>Compartilhe a bondade através da caridade, criando um impacto positivo e ajudando a construir um mundo melhor para todos.</BoxSub>
          <a target="_blank" href="https://www.edusabbag.com/">
          <BoxButton alt="Botão">Clique aqui!</BoxButton>
          </a>
        </BoxCard>
        <Imagem src={Foto2} alt="Caridade" title="Caridade"/>
      </Container>
      </Div>
    );
  }
  
  export default Card;

