import foto01 from './assets/evangelho/fotos/1.png';
import foto02 from './assets/evangelho/fotos/2.png';
import foto03 from './assets/evangelho/fotos/3.png';
import foto04 from './assets/evangelho/fotos/4.png';
import foto05 from './assets/evangelho/fotos/5.png';
import foto06 from './assets/evangelho/fotos/6.png';
import foto07 from './assets/evangelho/fotos/7.png';
import foto08 from './assets/evangelho/fotos/8.png';
import foto09 from './assets/evangelho/fotos/9.png';
import foto10 from './assets/evangelho/fotos/10.png';

import musica01 from './assets/evangelho/0000.mp3';
import musica02 from './assets/evangelho/0005.mp3';
import musica03 from './assets/evangelho/0006.mp3';
import musica04 from './assets/evangelho/0010.mp3';
import musica05 from './assets/evangelho/0011.mp3';
import musica06 from './assets/evangelho/0012.mp3';
import musica07 from './assets/evangelho/0020.mp3';
import musica08 from './assets/evangelho/0030.mp3';
import musica09 from './assets/evangelho/0040.mp3';
import musica10 from './assets/evangelho/0050.mp3';
import musica11 from './assets/evangelho/0060.mp3';
import musica12 from './assets/evangelho/0070.mp3';
import musica13 from './assets/evangelho/0080.mp3';
import musica14 from './assets/evangelho/0090.mp3';
import musica15 from './assets/evangelho/0100.mp3';
import musica16 from './assets/evangelho/0110.mp3';
import musica17 from './assets/evangelho/0120.mp3';
import musica18 from './assets/evangelho/0130.mp3';
import musica19 from './assets/evangelho/0140.mp3';
import musica20 from './assets/evangelho/0150.mp3';
import musica21 from './assets/evangelho/0160.mp3';
import musica22 from './assets/evangelho/0170.mp3';
import musica23 from './assets/evangelho/0180.mp3';
import musica24 from './assets/evangelho/0190.mp3';
import musica25 from './assets/evangelho/0200.mp3';
import musica26 from './assets/evangelho/0210.mp3';
import musica27 from './assets/evangelho/0220.mp3';
import musica28 from './assets/evangelho/0230.mp3';
import musica29 from './assets/evangelho/0240.mp3';
import musica30 from './assets/evangelho/0250.mp3';
import musica31 from './assets/evangelho/0260.mp3';
import musica32 from './assets/evangelho/0270.mp3';
import musica33 from './assets/evangelho/0290.mp3';
import musica34 from './assets/evangelho/0300.mp3';
import musica35 from './assets/evangelho/0310.mp3';
import musica36 from './assets/evangelho/0320.mp3';
import musica37 from './assets/evangelho/0330.mp3';
import musica38 from './assets/evangelho/0340.mp3';
import musica39 from './assets/evangelho/0350.mp3';
import musica40 from './assets/evangelho/0360.mp3';
import musica41 from './assets/evangelho/0370.mp3';
import musica42 from './assets/evangelho/0380.mp3';
import musica43 from './assets/evangelho/0390.mp3';
import musica44 from './assets/evangelho/0400.mp3';
import musica45 from './assets/evangelho/0410.mp3';
import musica46 from './assets/evangelho/0420.mp3';
import musica47 from './assets/evangelho/0430.mp3';
import musica48 from './assets/evangelho/0440.mp3';
import musica49 from './assets/evangelho/0450.mp3';
import musica50 from './assets/evangelho/0460.mp3';
import musica51 from './assets/evangelho/0470.mp3';
import musica52 from './assets/evangelho/0480.mp3';
import musica53 from './assets/evangelho/0490.mp3';
import musica54 from './assets/evangelho/0500.mp3';
import musica55 from './assets/evangelho/0510.mp3';
import musica56 from './assets/evangelho/0520.mp3';
import musica57 from './assets/evangelho/0530.mp3';
import musica58 from './assets/evangelho/0540.mp3';
import musica59 from './assets/evangelho/0550.mp3';
import musica60 from './assets/evangelho/0560.mp3';
import musica61 from './assets/evangelho/0570.mp3';
import musica62 from './assets/evangelho/0580.mp3';
import musica63 from './assets/evangelho/0590.mp3';
import musica64 from './assets/evangelho/0600.mp3';
import musica65 from './assets/evangelho/0610.mp3';
import musica66 from './assets/evangelho/0620.mp3';
import musica67 from './assets/evangelho/0630.mp3';
import musica68 from './assets/evangelho/0640.mp3';
import musica69 from './assets/evangelho/0650.mp3';
import musica70 from './assets/evangelho/0660.mp3';
import musica71 from './assets/evangelho/0670.mp3';
import musica72 from './assets/evangelho/0680.mp3';
import musica73 from './assets/evangelho/0690.mp3';
import musica74 from './assets/evangelho/0700.mp3';
import musica75 from './assets/evangelho/0710.mp3';
import musica76 from './assets/evangelho/0720.mp3';
import musica77 from './assets/evangelho/0730.mp3';
import musica78 from './assets/evangelho/0740.mp3';
import musica79 from './assets/evangelho/0750.mp3';
import musica80 from './assets/evangelho/0760.mp3';
import musica81 from './assets/evangelho/0770.mp3';
import musica82 from './assets/evangelho/0780.mp3';
import musica83 from './assets/evangelho/0790.mp3';
import musica84 from './assets/evangelho/0800.mp3';
import musica85 from './assets/evangelho/0810.mp3';
import musica86 from './assets/evangelho/0820.mp3';
import musica87 from './assets/evangelho/0830.mp3';
import musica88 from './assets/evangelho/0840.mp3';
import musica89 from './assets/evangelho/0850.mp3';
import musica90 from './assets/evangelho/0860.mp3';
import musica91 from './assets/evangelho/0870.mp3';
import musica92 from './assets/evangelho/0880.mp3';
import musica93 from './assets/evangelho/0890.mp3';
import musica94 from './assets/evangelho/0900.mp3';
import musica95 from './assets/evangelho/0910.mp3';
import musica96 from './assets/evangelho/0920.mp3';
import musica97 from './assets/evangelho/0930.mp3';
import musica98 from './assets/evangelho/0940.mp3';
import musica99 from './assets/evangelho/0950.mp3';
import musica100 from './assets/evangelho/0960.mp3';
import musica101 from './assets/evangelho/0970.mp3';
import musica102 from './assets/evangelho/0980.mp3';
import musica103 from './assets/evangelho/0990.mp3';



const music = [

    {
      "id": 1,
      "titulo": "Dicas: Evangelho no lar",
      "subtitulo": "Eduardo Sabbag",
      "audio": musica01,
      "album": foto01
    },
    {
      "id": 2,
      "titulo": "Diferentes mundos habitados",
      "subtitulo": "Eduardo Sabbag",
      "audio": musica02,
      "album": foto02,
      "trecho": "Questão 132: O que se deve entender por expiação? Resposta: Sofrimento moral ou físico que tem por fim a purificação do Espírito. A expiação constitui uma prova que, para ser útil e eficaz, deve ser voluntária e aceita com resignação."
    },
    {
      "id": 3,
      "titulo": " Capítulo IV",
      "subtitulo": "Evangelho Segundo o Espiritismo",
      "audio": musica03,
      "album": foto03,
      "trecho": `CAPÍTULO IV
      NINGUÉM PODERÁ VER O REINO DE DEUS SE NÃO NASCER DE NOVO
      
      Tema. — Ressurreição e reencarnação. — A reencarnação fortalece os laços de família, ao passo que a unicidade da existência os rompe. — Instruções dos Espíritos: Limites da encarnação; Necessidade da encarnação.
      
      1. Jesus, tendo vindo às cercanias de Cesareia de Filipe, interrogou assim seus discípulos: “Que dizem os homens, com relação ao Filho do Homem? Quem dizem que eu sou?” — Eles lhe responderam: “Dizem uns que és João Batista; outros, que Elias; outros, que Jeremias, ou algum dos profetas.” — Perguntou-lhes Jesus: “E vós, quem dizeis que eu sou?” — Simão Pedro, tomando a palavra, respondeu: “Tu és o Cristo, o Filho do Deus vivo:” — Replicou-lhe Jesus: “Bem-aventurado és, Simão, filho de Jonas, porque não foram a carne nem o sangue que isso te revelaram, mas meu Pai, que está nos céus.” (S. Mateus, 16:13 a 17; S. Marcos, 8:27 a 30.)
      
      2. Nesse ínterim, Herodes, o Tetrarca, ouvira falar de tudo o que fazia Jesus e seu espírito se achava em suspenso — porque uns diziam que João Batista ressuscitara dentre os mortos; outros que aparecera Elias; e outros que um dos antigos profetas ressuscitara. — Disse então Herodes: “Mandei cortar a cabeça a João Batista; quem é então esse de quem ouço dizer tão grandes coisas?” E ardia por vê-lo. (S. Marcos, 6:14 a 16; S. Lucas, 9:7 a 9.)
      
      3. (Após a transfiguração.) Seus discípulos então o interrogaram desta forma: “Por que dizem os escribas ser preciso que antes volte Elias?” — Jesus lhes respondeu: “É verdade que Elias há de vir e restabelecer todas as coisas: — mas, eu vos declaro que Elias já veio e eles não o conheceram e o trataram como lhes aprouve. É assim que farão sofrer o Filho do Homem.” — Então, seus discípulos compreenderam que fora de João Batista que ele falara. (S. Mateus, 17:10 a 13; S. Marcos, 9:11 a 13.)`
    },
    {
      "id": 4,
      "titulo": "Não vim destruir a lei",
      "subtitulo": "Evangelho Segundo o Espiritismo",
      "audio": musica04,
      "album": foto04,
      "trecho":`CAPÍTULO I
      NÃO VIM DESTRUIR A LEI
      
      Tema. — As três revelações: Moisés, Cristo, Espiritismo. — Aliança da ciência e da religião.
      — Instruções dos Espíritos: A nova era.
      
      1. Não penseis que eu tenha vindo destruir a lei ou os profetas: não os vim destruir, mas cumpri-los: — porquanto, em verdade vos digo que o céu e a Terra não passarão, sem que tudo o que se acha na lei esteja perfeitamente cumprido, enquanto reste um único iota e um único ponto.
      
      (S. Mateus, 5:17 e 18.)
      
      Moisés.
      
      2. Na lei moisaica, há duas partes distintas: a lei de Deus, promulgada no monte Sinai, e a lei civil ou disciplinar, decretada por Moisés. Uma é invariável; a outra, apropriada aos costumes e ao caráter do povo, se modifica com o tempo.
      
      A lei de Deus está formulada nos dez mandamentos seguintes:
      
      I. Eu sou o Senhor, vosso Deus, que vos tirei do Egito, da casa da servidão. Não tereis, diante de mim, outros deuses estrangeiros. — Não fareis imagem esculpida, nem figura alguma do que está em cima do céu, nem embaixo na Terra, nem do que quer que esteja nas águas sob a terra. Não os adorareis e não lhes prestareis culto soberano.
      
      II. Não pronunciareis em vão o nome do Senhor, vosso Deus.
      
      III. Lembrai-vos de santificar o dia do sábado.
      
      IV. Honrai a vosso pai e a vossa mãe, a fim de viverdes longo tempo na terra que o Senhor vosso Deus vos dará.
      
      V. Não mateis.
      
      VI. Não cometais adultério.
      
      VII. Não roubeis.
      
      VIII. Não presteis testemunho falso contra o vosso próximo.
      
      IX. Não desejeis a mulher do vosso próximo.
      
      X. Não cobiceis a casa do vosso próximo, nem o seu servo, nem a sua serva, nem o seu boi, nem o seu asno, nem qualquer das coisas que lhe pertençam.
      
      É de todos os tempos e de todos os países essa lei e tem, por isso mesmo, caráter divino. Todas as outras são leis que Moisés decretou, obrigado que se via a conter, pelo temor, um povo de seu natural turbulento e indisciplinado, no qual tinha ele de combater arraigados abusos e preconceitos, adquiridos durante a escravidão do Egito. Para imprimir autoridade às suas leis, houve de lhes atribuir origem divina, conforme o fizeram todos os legisladores dos povos primitivos. A autoridade do homem precisava apoiar-se na autoridade de Deus; mas, só a ideia de um Deus terrível podia impressionar criaturas ignorantes, em as quais ainda pouco desenvolvidos se encontravam o senso moral e o sentimento de uma justiça reta. É evidente que aquele que incluíra, entre os seus mandamentos, este: “Não matareis; não causareis dano ao vosso próximo”, não poderia contradizer-se, fazendo da exterminação um dever. As leis moisaicas, propriamente ditas, revestiam, pois, um caráter essencialmente transitório.`
    },
    {
      "id": 5,
      "titulo": "Quem é Jesus?",
      "subtitulo": "Eduardo Sabbag",
      "audio": musica05,
      "album": foto05,
      "trecho": `Questão 625: Qual o tipo mais perfeito que Deus ofereceu ao homem para lhe servir de guia e modelo? Resposta: Jesus.`
    },
    {
      "id": 6,
      "titulo": "Jesus",
      "subtitulo": "Eduardo Sabbag",
      "audio": musica06,
      "album": foto06,
      "trecho": `O Espiritismo considera Jesus como um dos maiores exemplos de virtude e amor, mas difere de algumas interpretações cristãs tradicionais. Por exemplo, o Espiritismo não adota a Trindade (Pai, Filho e Espírito Santo) como uma única entidade.`
    },
    {
      "id": 7,
      "titulo": "Ciência e Religião",
      "subtitulo": "Eduardo Sabbag",
      "audio": musica07,
      "album": foto03,
      "trecho": `O Espiritismo encoraja a busca pelo conhecimento científico e respeita os avanços e descobertas da ciência, mas também reconhece que há fenômenos além do alcance da ciência materialista, como os relacionados à espiritualidade e à vida após a morte.`
    },
    {
      "id": 8,
      "titulo": "Podemos ver em Jesus um modelo perfeito?",
      "subtitulo": "Eduardo Sabbag",
      "audio": musica08,
      "album": foto04,
      "trecho": `Questão 625-a: Podemos ver em Jesus um modelo perfeito?

      Resposta: Certamente; Jesus é o tipo da perfeição moral a que a Humanidade pode aspirar na Terra. Deus no-lo oferece como o mais perfeito modelo e a doutrina que ensinou é a expressão mais pura da lei do Senhor, porque, sendo o mais puro de todos os justos, o Espírito que o animava estava mais próximo de Deus.`
    },
    {
      "id": 9,
      "titulo": "Qual o objetivo da reencarnação?",
      "subtitulo": "Eduardo Sabbag",
      "audio": musica09,
      "album": foto01,
      "trecho": `Questão 166: Qual o objetivo da reencarnação? Resposta: Expiação, aprimoramento progressivo da humanidade.`
    },
    {
      "id": 10,
      "titulo": "Qual é a visão do Espiritismo sobre Jesus?",
      "subtitulo": "Eduardo Sabbag",
      "audio": musica10,
      "album": foto05,
      "trecho": `Questão 625-a: "Podemos ver em Jesus um modelo perfeito?  Certamente; Jesus é o tipo da perfeição moral a que a Humanidade pode aspirar na Terra. Deus no-lo oferece como o mais perfeito modelo e a doutrina que ensinou é a expressão mais pura da lei do Senhor, porque, sendo o mais puro de todos os justos, o Espírito que o animava estava mais próximo de Deus."`
    },
    {
      "id": 11,
      "titulo": "Do átomo ao anjo!",
      "subtitulo": "Eduardo Sabbag",
      "audio": musica11,
      "album": foto08,
      "trecho": `"Do átomo ao anjo!"`
    },
    {
      "id": 12,
      "titulo": "Praticar a caridade",
      "subtitulo": "Eduardo Sabbag",
      "audio": musica12,
      "album": foto09,
      "trecho": `Questão 1013: "O espírito puro, que não tem mais necessidade de expiações, está sujeito a sofrer perturbações e a experimentar sentimentos penosos?"

      Resposta: "Não; ele repousa em paz, sem inquietações. Goza de uma felicidade inalterável, porque nada perturba a sua serenidade."`
    },
    {
      "id": 13,
      "titulo": "Diferentes estados da alma na erraticidade",
      "subtitulo": "Eduardo Sabbag",
      "audio": musica13,
      "album": foto07,
      "trecho": `Questão 55: "Há habitantes em todos os globos?"

      Resposta: "Sim, e o homem terreno é, frequentemente, o último e o mais imperfeito. Há mundos onde os homens são menos materiais do que na Terra e outros onde são mais materiais do que nela."`
    },
    {
      "id": 14,
      "titulo": "Planetas",
      "subtitulo": "Eduardo Sabbag",
      "audio": musica14,
      "album": foto10,
      "trecho": `Planetas Primitivos: São aqueles em que os habitantes ainda se encontram em estágios iniciais de desenvolvimento moral e intelectual. Geralmente, há uma predominância de instintos sobre a razão.

Planetas de Provas e Expiações: São mundos onde os habitantes passam por provas e expiações, ou seja, enfrentam desafios e superam dificuldades para aprimorar suas virtudes e corrigir seus erros passados.

Planetas de Regeneração: São mundos em que a maioria dos habitantes já alcançou um certo grau de desenvolvimento moral e intelectual. Há uma predominância do bem sobre o mal, e os espíritos têm mais oportunidades de progresso.

Planetas Ditos de Felicidade: São mundos em que os habitantes alcançaram um alto grau de perfeição moral e intelectual. A felicidade e a harmonia são predominantes, e os espíritos têm poucas provas a superar.`
    },
    {
      "id": 15,
      "titulo": "Causa das misérias humanas",
      "subtitulo": "Eduardo Sabbag",
      "audio": musica15,
      "album": foto01,
      "trecho": ` `
    },
    {
      "id": 16,
      "titulo": "Mundos superiores e inferiores",
      "subtitulo": "Eduardo Sabbag",
      "audio": musica16,
      "album": foto02,
      "trecho": ` `
    },
    {
      "id": 17,
      "titulo": "Expiação financeira",
      "subtitulo": "Eduardo Sabbag",
      "audio": musica17,
      "album": foto03,
      "trecho": ` `
    },
    {
      "id": 18,
      "titulo": "Há muitas moradas na casa de meu pai",
      "subtitulo": "Eduardo Sabbag",
      "audio": musica18,
      "album": foto04,
      "trecho": ` `
    },
    {
      "id": 19,
      "titulo": "Evolução",
      "subtitulo": "Eduardo Sabbag",
      "audio": musica19,
      "album": foto05,
      "trecho": ` `
    },
    {
      "id": 20,
      "titulo": "Ressurreição e Reencarnação",
      "subtitulo": "Eduardo Sabbag",
      "audio": musica20,
      "album": foto06,
      "trecho": ` `
    },
    {
      "id": 21,
      "titulo": "A Reencarnação e os Laços de Família",
      "subtitulo": "Eduardo Sabbag",
      "audio": musica21,
      "album": foto01,
      "trecho": ` `
    },
    {
      "id": 22,
      "titulo": "Evangelho no lar #22",
      "subtitulo": "Eduardo Sabbag",
      "audio": musica22,
      "album": foto10,
      "trecho": ` `
    },
    {
      "id": 23,
      "titulo": "Evangelho no lar #23",
      "subtitulo": "Eduardo Sabbag",
      "audio": musica23,
      "album": foto02,
      "trecho": ` `
    },
    {
      "id": 24,
      "titulo": "Evangelho no lar #24",
      "subtitulo": "Eduardo Sabbag",
      "audio": musica24,
      "album": foto07,
      "trecho": ` `
    },
    {
      "id": 25,
      "titulo": "Evangelho no lar #25",
      "subtitulo": "Eduardo Sabbag",
      "audio": musica25,
      "album": foto09,
      "trecho": ` `
    },
    {
      "id": 26,
      "titulo": "Evangelho no lar #26",
      "subtitulo": "Eduardo Sabbag",
      "audio": musica26,
      "album": foto02,
      "trecho": ` `
    },
    {
      "id": 27,
      "titulo": "Evangelho no lar #27",
      "subtitulo": "Eduardo Sabbag",
      "audio": musica27,
      "album": foto03,
      "trecho": ` `
    },
    {
      "id": 28,
      "titulo": "Evangelho no lar #28",
      "subtitulo": "Eduardo Sabbag",
      "audio": musica28,
      "album": foto04,
      "trecho": ` `
    },
    {
      "id": 29,
      "titulo": "Evangelho no lar #29",
      "subtitulo": "Eduardo Sabbag",
      "audio": musica29,
      "album": foto05,
      "trecho": ` `
    },
    {
      "id": 30,
      "titulo": "Evangelho no lar #30",
      "subtitulo": "Eduardo Sabbag",
      "audio": musica30,
      "album": foto06,
      "trecho": ` `
    },
    {
      "id": 31,
      "titulo": "Evangelho no lar #31",
      "subtitulo": "Eduardo Sabbag",
      "audio": musica31,
      "album": foto07,
      "trecho": ` `
    },
    {
      "id": 32,
      "titulo": "Evangelho no lar #32",
      "subtitulo": "Eduardo Sabbag",
      "audio": musica32,
      "album": foto08,
      "trecho": ` `
    },
    {
      "id": 33,
      "titulo": "Evangelho no lar #33",
      "subtitulo": "Eduardo Sabbag",
      "audio": musica33,
      "album": foto09,
      "trecho": ` `
    },
    {
      "id": 34,
      "titulo": "Evangelho no lar #34",
      "subtitulo": "Eduardo Sabbag",
      "audio": musica34,
      "album": foto10,
      "trecho": ` `
    },
    {
      "id": 35,
      "titulo": "Evangelho no lar #35",
      "subtitulo": "Eduardo Sabbag",
      "audio": musica35,
      "album": foto01,
      "trecho": ` `
    },
    {
      "id": 36,
      "titulo": "Evangelho no lar #36",
      "subtitulo": "Eduardo Sabbag",
      "audio": musica36,
      "album": foto02,
      "trecho": ` `
    },
    {
      "id": 37,
      "titulo": "Evangelho no lar #37",
      "subtitulo": "Eduardo Sabbag",
      "audio": musica37,
      "album": foto03,
      "trecho": ` `
    },
    {
      "id": 38,
      "titulo": "Evangelho no lar #38",
      "subtitulo": "Eduardo Sabbag",
      "audio": musica38,
      "album": foto04,
      "trecho": ` `
    },
    {
      "id": 39,
      "titulo": "Evangelho no lar #39",
      "subtitulo": "Eduardo Sabbag",
      "audio": musica39,
      "album": foto05,
      "trecho": ` `
    },
    {
      "id": 40,
      "titulo": "Evangelho no lar #40",
      "subtitulo": "Eduardo Sabbag",
      "audio": musica40,
      "album": foto06,
      "trecho": ` `
    },
    {
      "id": 41,
      "titulo": "Evangelho no lar #41",
      "subtitulo": "Eduardo Sabbag",
      "audio": musica41,
      "album": foto07,
      "trecho": ` `
    },
    {
      "id": 42,
      "titulo": "Evangelho no lar #42",
      "subtitulo": "Eduardo Sabbag",
      "audio": musica42,
      "album": foto08,
      "trecho": ` `
    },
    {
      "id": 43,
      "titulo": "Evangelho no lar #43",
      "subtitulo": "Eduardo Sabbag",
      "audio": musica43,
      "album": foto09,
      "trecho": ` `
    },
    {
      "id": 44,
      "titulo": "Evangelho no lar #44",
      "subtitulo": "Eduardo Sabbag",
      "audio": musica44,
      "album": foto10,
      "trecho": ` `
    },
    {
      "id": 45,
      "titulo": "Evangelho no lar #45",
      "subtitulo": "Eduardo Sabbag",
      "audio": musica45,
      "album": foto01,
      "trecho": ` `
    },
    {
      "id": 46,
      "titulo": "Evangelho no lar #46",
      "subtitulo": "Eduardo Sabbag",
      "audio": musica46,
      "album": foto02,
      "trecho": ` `
    },
    {
      "id": 47,
      "titulo": "Evangelho no lar #47",
      "subtitulo": "Eduardo Sabbag",
      "audio": musica47,
      "album": foto03,
      "trecho": ` `
    },
    {
      "id": 48,
      "titulo": "Evangelho no lar #48",
      "subtitulo": "Eduardo Sabbag",
      "audio": musica48,
      "album": foto04,
      "trecho": ` `
    },
    {
      "id": 49,
      "titulo": "Evangelho no lar #49",
      "subtitulo": "Eduardo Sabbag",
      "audio": musica49,
      "album": foto05,
      "trecho": ` `
    },
    {
      "id": 50,
      "titulo": "Evangelho no lar #50",
      "subtitulo": "Eduardo Sabbag",
      "audio": musica50,
      "album": foto06,
      "trecho": ` `
    },
    {
      "id": 51,
      "titulo": "Evangelho no lar #51",
      "subtitulo": "Eduardo Sabbag",
      "audio": musica51,
      "album": foto07,
      "trecho": ` `
    },
    {
      "id": 52,
      "titulo": "Evangelho no lar #52",
      "subtitulo": "Eduardo Sabbag",
      "audio": musica52,
      "album": foto08,
      "trecho": ` `
    },
    {
      "id": 53,
      "titulo": "Evangelho no lar #53",
      "subtitulo": "Eduardo Sabbag",
      "audio": musica53,
      "album": foto09,
      "trecho": ` `
    },
    {
      "id": 54,
      "titulo": "Evangelho no lar #54",
      "subtitulo": "Eduardo Sabbag",
      "audio": musica54,
      "album": foto10,
      "trecho": ` `
    },
    {
      "id": 55,
      "titulo": "Evangelho no lar #55",
      "subtitulo": "Eduardo Sabbag",
      "audio": musica55,
      "album": foto01,
      "trecho": ` `
    },
    {
      "id": 56,
      "titulo": "Evangelho no lar #56",
      "subtitulo": "Eduardo Sabbag",
      "audio": musica56,
      "album": foto02,
      "trecho": ` `
    },
    {
      "id": 57,
      "titulo": "Evangelho no lar #57",
      "subtitulo": "Eduardo Sabbag",
      "audio": musica57,
      "album": foto03,
      "trecho": ` `
    },
    {
      "id": 58,
      "titulo": "Evangelho no lar #58",
      "subtitulo": "Eduardo Sabbag",
      "audio": musica58,
      "album": foto04,
      "trecho": ` `
    },
    {
      "id": 59,
      "titulo": "Evangelho no lar #59",
      "subtitulo": "Eduardo Sabbag",
      "audio": musica59,
      "album": foto05,
      "trecho": ` `
    },
    {
      "id": 60,
      "titulo": "Evangelho no lar #60",
      "subtitulo": "Eduardo Sabbag",
      "audio": musica60,
      "album": foto06,
      "trecho": ` `
    },
    {
      "id": 61,
      "titulo": "Evangelho no lar #61",
      "subtitulo": "Eduardo Sabbag",
      "audio": musica61,
      "album": foto07,
      "trecho": ` `
    },
    {
      "id": 62,
      "titulo": "Evangelho no lar #62",
      "subtitulo": "Eduardo Sabbag",
      "audio": musica62,
      "album": foto08,
      "trecho": ` `
    },
    {
      "id": 63,
      "titulo": "Evangelho no lar #63",
      "subtitulo": "Eduardo Sabbag",
      "audio": musica63,
      "album": foto09,
      "trecho": ` `
    },
    {
      "id": 64,
      "titulo": "Evangelho no lar #64",
      "subtitulo": "Eduardo Sabbag",
      "audio": musica64,
      "album": foto10,
      "trecho": ` `
    },
    {
      "id": 65,
      "titulo": "Evangelho no lar #65",
      "subtitulo": "Eduardo Sabbag",
      "audio": musica65,
      "album": foto01,
      "trecho": ` `
    },
    {
      "id": 66,
      "titulo": "Evangelho no lar #66",
      "subtitulo": "Eduardo Sabbag",
      "audio": musica66,
      "album": foto02,
      "trecho": ` `
    },
    {
      "id": 67,
      "titulo": "Evangelho no lar #67",
      "subtitulo": "Eduardo Sabbag",
      "audio": musica67,
      "album": foto03,
      "trecho": ` `
    },
    {
      "id": 68,
      "titulo": "Evangelho no lar #68",
      "subtitulo": "Eduardo Sabbag",
      "audio": musica68,
      "album": foto04,
      "trecho": ` `
    },
    {
      "id": 69,
      "titulo": "Evangelho no lar #69",
      "subtitulo": "Eduardo Sabbag",
      "audio": musica69,
      "album": foto05,
      "trecho": ` `
    },
    {
      "id": 70,
      "titulo": "Evangelho no lar #70",
      "subtitulo": "Eduardo Sabbag",
      "audio": musica70,
      "album": foto06,
      "trecho": ` `
    },
    {
      "id": 71,
      "titulo": "Evangelho no lar #71",
      "subtitulo": "Eduardo Sabbag",
      "audio": musica71,
      "album": foto07,
      "trecho": ` `
    },
    {
      "id": 72,
      "titulo": "Evangelho no lar #72",
      "subtitulo": "Eduardo Sabbag",
      "audio": musica72,
      "album": foto08,
      "trecho": ` `
    },
    {
      "id": 73,
      "titulo": "Evangelho no lar #73",
      "subtitulo": "Eduardo Sabbag",
      "audio": musica73,
      "album": foto09,
      "trecho": ` `
    },
    {
      "id": 74,
      "titulo": "Evangelho no lar #74",
      "subtitulo": "Eduardo Sabbag",
      "audio": musica74,
      "album": foto10,
      "trecho": ` `
    },
    {
      "id": 75,
      "titulo": "Evangelho no lar #75",
      "subtitulo": "Eduardo Sabbag",
      "audio": musica75,
      "album": foto01,
      "trecho": ` `
    },
    {
      "id": 76,
      "titulo": "Evangelho no lar #76",
      "subtitulo": "Eduardo Sabbag",
      "audio": musica76,
      "album": foto02,
      "trecho": ` `
    },
    {
      "id": 77,
      "titulo": "Evangelho no lar #77",
      "subtitulo": "Eduardo Sabbag",
      "audio": musica77,
      "album": foto03,
      "trecho": ` `
    },
    {
      "id": 78,
      "titulo": "Evangelho no lar #78",
      "subtitulo": "Eduardo Sabbag",
      "audio": musica78,
      "album": foto04,
      "trecho": ` `
    },
    {
      "id": 79,
      "titulo": "Evangelho no lar #79",
      "subtitulo": "Eduardo Sabbag",
      "audio": musica79,
      "album": foto05,
      "trecho": ` `
    },
    {
      "id": 80,
      "titulo": "Evangelho no lar #80",
      "subtitulo": "Eduardo Sabbag",
      "audio": musica80,
      "album": foto06,
      "trecho": ` `
    },
    {
      "id": 81,
      "titulo": "Evangelho no lar #81",
      "subtitulo": "Eduardo Sabbag",
      "audio": musica81,
      "album": foto07,
      "trecho": ` `
    },
    {
      "id": 82,
      "titulo": "Evangelho no lar #82",
      "subtitulo": "Eduardo Sabbag",
      "audio": musica82,
      "album": foto08,
      "trecho": ` `
    },
    {
      "id": 83,
      "titulo": "Evangelho no lar #83",
      "subtitulo": "Eduardo Sabbag",
      "audio": musica83,
      "album": foto09,
      "trecho": ` `
    },
    {
      "id": 84,
      "titulo": "Evangelho no lar #84",
      "subtitulo": "Eduardo Sabbag",
      "audio": musica84,
      "album": foto10,
      "trecho": ` `
    },
    {
      "id": 85,
      "titulo": "Evangelho no lar #85",
      "subtitulo": "Eduardo Sabbag",
      "audio": musica85,
      "album": foto01,
      "trecho": ` `
    },
    {
      "id": 86,
      "titulo": "Evangelho no lar #86",
      "subtitulo": "Eduardo Sabbag",
      "audio": musica86,
      "album": foto02,
      "trecho": ` `
    },
    {
      "id": 87,
      "titulo": "Evangelho no lar #87",
      "subtitulo": "Eduardo Sabbag",
      "audio": musica87,
      "album": foto03,
      "trecho": ` `
    },
    {
      "id": 88,
      "titulo": "Evangelho no lar #88",
      "subtitulo": "Eduardo Sabbag",
      "audio": musica88,
      "album": foto04,
      "trecho": ` `
    },
    {
      "id": 89,
      "titulo": "Evangelho no lar #89",
      "subtitulo": "Eduardo Sabbag",
      "audio": musica89,
      "album": foto05,
      "trecho": ` `
    },
    {
      "id": 90,
      "titulo": "Evangelho no lar #90",
      "subtitulo": "Eduardo Sabbag",
      "audio": musica90,
      "album": foto06,
      "trecho": ` `
    },
    {
      "id": 91,
      "titulo": "Evangelho no lar #91",
      "subtitulo": "Eduardo Sabbag",
      "audio": musica91,
      "album": foto07,
      "trecho": ` `
    },
    {
      "id": 92,
      "titulo": "Evangelho no lar #92",
      "subtitulo": "Eduardo Sabbag",
      "audio": musica92,
      "album": foto08,
      "trecho": ` `
    },
    {
      "id": 93,
      "titulo": "Evangelho no lar #93",
      "subtitulo": "Eduardo Sabbag",
      "audio": musica93,
      "album": foto09,
      "trecho": ` `
    },
    {
      "id": 94,
      "titulo": "Evangelho no lar #94",
      "subtitulo": "Eduardo Sabbag",
      "audio": musica94,
      "album": foto10,
      "trecho": ` `
    },
    {
      "id": 95,
      "titulo": "Evangelho no lar #95",
      "subtitulo": "Eduardo Sabbag",
      "audio": musica95,
      "album": foto01,
      "trecho": ` `
    },
    {
      "id": 96,
      "titulo": "Evangelho no lar #96",
      "subtitulo": "Eduardo Sabbag",
      "audio": musica96,
      "album": foto02,
      "trecho": ` `
    },
    {
      "id": 97,
      "titulo": "Evangelho no lar #97",
      "subtitulo": "Eduardo Sabbag",
      "audio": musica97,
      "album": foto03,
      "trecho": ` `
    },
    {
      "id": 98,
      "titulo": "Evangelho no lar #98",
      "subtitulo": "Eduardo Sabbag",
      "audio": musica98,
      "album": foto04,
      "trecho": ` `
    },
    {
      "id": 99,
      "titulo": "Evangelho no lar #99",
      "subtitulo": "Eduardo Sabbag",
      "audio": musica99,
      "album": foto05,
      "trecho": ` `
    },
    {
      "id": 100,
      "titulo": "Evangelho no lar #100",
      "subtitulo": "Eduardo Sabbag",
      "audio": musica100,
      "album": foto06,
      "trecho": ` `
    }    
  ];

  export default music;  