import React from "react";
import styled from 'styled-components';
import Banner from '../../assets/icon/banner.png';
import Down from '../../assets/icon/down.png';


const Container = styled.div`
  display: flex;
  flex-direction: row;
  position: relative;

  @media (max-width: 1000px){
    
    }
`;


const Imagem = styled.img`
    width: 100%;
    z-index: 0;

    @media (max-width: 1000px){
    width: 1000px;
    }
`;

const Control = styled.div`
    padding: 1rem;
    z-index: 10000;
    position: absolute;
    left: 50%;
    top: 25%;
    transform: translate(-50%,-50%);

    @media (max-width: 1000px){
    width: 300px;
    top: 30%;
    }
`;

const Tilulo = styled.h1`
    text-align: center;
    color: white;
    font-size: 3rem;
    font-weight: 600;
    margin-bottom: -4px;

    @media (max-width: 1000px){
        font-size: 2rem;
    }
`;

const Sub = styled.p`
    text-align: center;
    color: white;
    font-size: 1.2rem;
    font-weight: 400;

    @media (max-width: 1000px){
        font-size: 1rem;
    }
`;

const Icone = styled.img`
    width: 50px;
    display: block;
    margin: 0 auto;
`;



function Principal() {
    return (
      <Container>
        <Control>
            <Tilulo>Espiritismo Raiz</Tilulo>
            <Sub>Desperte a sua Espiritualidade: Navegue pelo Conhecimento Profundo e Transformador.</Sub>    
            <Icone src={Down} alt="Icone"/>
        </Control> 
            <Imagem src={Banner} alt="Banner" title="Banner"/>
      </Container>
    );
  }
  
  export default Principal;

