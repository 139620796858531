import React from "react";
import styled from 'styled-components';

const Container = styled.div`
  text-align: center;
  padding: 0.3rem;
  background: white;
`;


const Titulo = styled.h1`
    color: black;
    font-weight: 600;
    font-size: 1.1rem;
`;

function Toponame() {
    return (
      <Container>
        <Titulo>Espiritismo Raiz</Titulo>
      </Container>
    );
  }
  
  export default Toponame;

