import React from "react";
import styled from 'styled-components';
import Menu from "../Menu/Menu";
import Footer from "../Footer/Footer";



const Container = styled.div`
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 1rem;
  background: white;
  width: 800px;
  margin: 0 auto;

  @media (max-width: 1000px){
    width: 400px;
    }
`;


const Title = styled.h1`
   font-size: 2rem;
  font-weight: 600;
  margin-bottom: -10px;
`;

const SubTitle = styled.p`
  color: gray;
  font-size: 1.3rem;
  font-weight: 400;
  margin-bottom: -10px;
`;


function Sobre() {
    return (
      <div>
        <Menu/>

        <Container>
          <Title>Política de Privacidade - Espiritismo Raiz Eduardo Sabbag</Title>
          <Title>Introdução</Title>
          <SubTitle>Bem-vindo(a) ao aplicativo Espiritismo Raiz Eduardo Sabbag. Esta Política de Privacidade explica como coletamos, usamos e protegemos suas informações ao utilizar nosso aplicativo. Ao aceitar esta Política de Privacidade, você concorda com as práticas descritas aqui.</SubTitle>

          <Title>Informações que Coletamos</Title>
          <Title>Nome e Cidade para Oração (Opcional)</Title>
          <SubTitle>Oferecemos a opção de fornecer seu nome e cidade para incluí-lo(a) em nossas orações. Este é um serviço completamente opcional. O aplicativo continuará funcionando normalmente caso você opte por não fornecer essas informações.</SubTitle>
          <Title>Uso das Informações</Title>
          <SubTitle>As informações coletadas são usadas exclusivamente para incluir você em nossas preces e orações.</SubTitle>
          <Title>Compartilhamento de Informações</Title>
          <SubTitle>As informações fornecidas por você não serão compartilhadas com terceiros. Elas são tratadas com total confidencialidade.</SubTitle>
          <Title>Segurança</Title>
          <SubTitle>Implementamos medidas de segurança para proteger as informações fornecidas por você.</SubTitle>
          <Title>Acesso e Controle das Suas Informações</Title>
          <SubTitle>Você pode solicitar a exclusão das suas informações a qualquer momento. Basta entrar em contato conosco através dos meios disponibilizados no aplicativo.</SubTitle>
          <Title>Alterações nesta Política de Privacidade</Title>
          <SubTitle>Se houver alterações nesta política, iremos notificá-lo(a) através do aplicativo.</SubTitle>
          <Title>Contato</Title>
          <SubTitle>Se tiver dúvidas ou preocupações sobre nossa Política de Privacidade, entre em contato conosco através dos meios disponibilizados no aplicativo.</SubTitle>
        </Container>
        <Footer/>
        </div>
    );
  }
  
  export default Sobre;

