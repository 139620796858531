import Banner from "../../components/Banner/Banner.js";
import Card from "../../components/Card/Card.js";
import Principal from "../../components/Principal/Principal.js";
import { motion } from 'framer-motion';
import Toponame  from "../NameTopo/index.js";
import Menu from "../Menu/Menu.js";
import Footer from "../Footer/Footer.js";

function Home() {
  
  return (
    <motion.div
    initial={{ opacity: 0 }}
    animate={{ opacity: 1 }}
    transition={{ duration: 0.3 }}
    >
      <div>
        <Toponame/>
        <Menu />
        <Principal/>
        <Card/>
        <Banner/>
        <Footer/>
      </div>
    </motion.div>
  );
}

export default Home;
