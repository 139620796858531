import { useState, useEffect } from 'react';
import styled from 'styled-components';
import Menu from '../Menu/Menu.js';
import Footer from '../Footer/Footer.js';

const Dive = styled.div`
  width: 100vw;
  height: 100vh;
  background: black;
`;

const StyledContainer = styled.div`
  padding: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background: white;
`;

const ControllText = styled.div`
  display: flex;
  justify-content: center;
  align-self: center;
  padding: 1rem;
  flex-direction: column;
  width: 900px;
  


  
  @media (max-width: 1000px){
    width: 400px;
    }
  `;

const Title = styled.h1`
  width: 100%;
  font-size: 2rem;
  font-weight: 600;
  text-align: center;
`;

const SubTitle = styled.p`
  color: gray;
  font-size: 1.3rem;
  font-weight: 400;
  width: 600px;
  text-align: center;

  width: 100%;
`;

const Table = styled.table`
  border-collapse: collapse;
  width: 1000px;

  @media (max-width: 1000px){
    margin: 0 auto;
    width: 100%;
    }
`;

const TableHead = styled.thead`
  background-color: Black;
  color: #ffffff;

  tr{
    font-size: 1.3rem;
    text-align: left;

    th{
      padding: 1rem;
    }
  }
`;

const TableRow = styled.tr`
  font-size: 1.2rem;

  @media (max-width: 1000px){
      font-size: 1rem;
    }


  &:nth-child(even) {
    background-color: #f3f3f3;
  }

  &:last-child {
    border-bottom: 2px solid #000;
  }
`;

const TableCell = styled.td`
  padding: 12px 15px;
  border-bottom: 1px solid #dddddd;
`;

const ActiveRow = styled.tr`
  font-weight: bold;
  color: #009879;
`;

const DivInput = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const Input = styled.input`
  padding: 1rem;
  width: 300px;
  margin-bottom: 10px;
  border-radius: 4px;
`;

const Button = styled.button`
  padding: 1rem;
  width: 200px;
  background: black;
  font-size: 1rem;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;

  transition: 500ms;

&:hover{
  background: gray;
}
`;

const YourComponent = () => {
  const [senha, setSenha] = useState('');
  const [senhaCorreta, setSenhaCorreta] = useState(false);
  const [data, setData] = useState([]);

  const senhaCorretaValor = 'Espiritismo123456@'; // Defina a senha correta

  const verificarSenha = () => {
    if (senha === senhaCorretaValor) {
      setSenhaCorreta(true);
    } else {
      alert('Senha incorreta. Tente novamente.');
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch('https://xedjwbcidbilzocsnpwu.supabase.co/rest/v1/Task', {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'apikey': 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6InhlZGp3YmNpZGJpbHpvY3NucHd1Iiwicm9sZSI6ImFub24iLCJpYXQiOjE2OTI2NTc0NDIsImV4cCI6MjAwODIzMzQ0Mn0.NtLFPPfNAs6zcdRdyzhqpM5GsjqCISES1UgpCrGJJvg' // Substitua com sua chave de API
          }
        });

        if (!response.ok) {
          throw new Error('Não foi possível obter os dados da API');
        }

        const result = await response.json();
        setData(result);
      } catch (error) {
        console.error(error);
      }
    };

    if (senhaCorreta) {
      fetchData();
    }
  }, [senhaCorreta]);

  return (
    <Dive> 
    <Menu/>
    <StyledContainer>
     
      {senhaCorreta ? (
        <ControllText>
          <Title>Lista de Nomes</Title>
          <Table>
            <TableHead>
              <tr>
                <th>Nome</th>
                <th>Cidade</th>
              </tr>
            </TableHead>
            <tbody>
              {data.map(item => (
                <TableRow key={item.id}>
                  <TableCell>{item.nome}</TableCell>
                  <TableCell>{item.cidade}</TableCell>
                </TableRow>
              ))}
            </tbody>
          </Table>
        </ControllText>
      ) : (
        <DivInput>
          <h1>Admin:</h1>
          <Input
            type="password"
            value={senha}
            onChange={(e) => setSenha(e.target.value)}
            placeholder="Insira a senha"
          />
          <Button onClick={verificarSenha}>Entrar</Button>
        </DivInput>
      )}
      
    </StyledContainer>
    <Footer/>
    </Dive>
  );
};

export default YourComponent;

