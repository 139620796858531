import React from "react";
import styled from 'styled-components';
import Celular from '../../assets/celular.png';
import Flecha from '../../assets/icon/flecha.png';
import Apple from '../../assets/icon/apple.png';
import Play from '../../assets/icon/playstore.png';
import Logo from '../../assets/icon.png';

const Container = styled.div`
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 1rem;
  background: white;
`;

const TituloP = styled.h1`
    text-align: center;
    margin: 2rem;
    font-size: 3rem;
    
    @media (max-width: 1000px){
        font-size: 2rem;
    }
`;


const Control = styled.div`
    padding: 1rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    
    @media (max-width: 1000px){
        flex-direction: column;
    }
`;

const Imagem = styled.img`
    max-width: 500px;
    width: 100%;

    @media (max-width: 1000px){
        margin-top: 0.1rem;
    }
`;


const ControlText = styled.div`
    list-style: none;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 1rem;
`;


const Logonalto = styled.img`
    width: 140px;
    height: 140px;
    margin-bottom: -30px;
    margin-left: -20px;


    @media (max-width: 1000px){
        margin: -30px auto;
    }
`;

const Titulo = styled.h1`
    color: #4D4D4D;
    font-weight: 600;
    font-size: 3rem;
    text-align: left;
    margin-bottom: -21px;

    @media (max-width: 1000px){
        font-size: 2.5rem;
        text-align: center;
    }
`;

const Sub = styled.p`
    text-align: left;
    color: #4D4D4D;
    font-weight: 200;
    font-size: 2rem;
    font-weight: 400;
    width: 300px;

    @media (max-width: 1000px){
        font-size: 1.5rem;
        text-align: center;
    }
`;

const ControleAll = styled.div`
    display: flex;
    flex-direction: row;

    @media (max-width: 1000px){
        flex-direction: column;
    }
`;


const Botao = styled.button`
    border: none;
    width: 200px;
    padding: 10px;
    border-radius: 10px;
    cursor: pointer;
    background: #4D4D4D;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: transform .2s;

    @media (max-width: 1000px){
        margin: 0 auto;
    }
`;

const TextBotao = styled.h1`
    color: white;
    font-size: 1rem;
    font-weight: 600;
    margin-right: 6px;
`;

const IconBotao = styled.img`
    width: 33px;
`;

const ControlIcones = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-left: 1rem;
  

    :hover{
        transform: scale(1.2);
    }
`;

const Icones = styled.img`
    width: 40px;
    margin: 1rem;
    cursor: pointer;
    transition: 500ms;

    @media (max-width: 1000px){
        width: 35px;
        margin-top: 2rem;
    }
`;

function Banner() {
    return (
      <Container>
        <TituloP>Nosso Aplicativo:</TituloP>
        <Control>
            <ControlText>
                <Logonalto src={Logo} alt="Logo Espiritismo" title="Logo"/>
                <Titulo>Espiritismo Raiz</Titulo>
                <Sub>O aplicativo que está faltando no seu smartphone!</Sub>
                <ControleAll>
                    <Botao>
                        <TextBotao>Baixar agora!</TextBotao>
                        <IconBotao src={Flecha} alt="Icone de Baixar"/>
                    </Botao>
                    <ControlIcones>
                        <a  target="_blank" href="https://apps.apple.com/us/app/espiritismoraiz-eduardo-sabbag/id6468327741">
                        <Icones title="Apple" src={Apple} alt="Icone Apple"/>
                        </a>
                        <a target="_blank" href="https://play.google.com/store/apps/details?id=com.app.espiritismoraiz">
                            <Icones title="Android" src={Play} alt="Icone Google Play"/>
                        </a>
                    </ControlIcones>
                </ControleAll>
            </ControlText>
            <Imagem src={Celular} alt="Aplicativo" title="Aplicativo"/>
        </Control>
      </Container>
    );
  }
  
  export default Banner;

