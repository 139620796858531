import { BrowserRouter } from 'react-router-dom';
import styled from 'styled-components';
import Logo from '../src/assets/LOGO-MOBILE.png';
import Apple from '../src/assets/icon/apple.png';
import Google from '../src/assets/icon/playstore.png';
import Facebook from '../src/assets/icon/facebook.png';
import Tiktok from '../src/assets/icon/tiktok.png';
import Instagram from '../src/assets/icon/instagram.png';
import Caridade from '../src/assets/icon/caridade.png';
import Youtube2 from '../src/assets/icon/youtube2.png';
import Back from '../src/assets/back.png';
import BackgroundF from '../src/assets/Black6.jpg';
import { Link } from 'react-router-dom';
import '../src/Global.css';

const StyledLink = styled(Link)`
  color: white;
  text-decoration: none;
  font-weight: bold;

  &:hover {
    text-decoration: underline;
  } 
  `;


const Background = styled.img`
  display: block;
  position: absolute;
  width: 100vw;
  height: 190vh;
  z-index: -2;
  

  
  @media (max-width: 1000px){
   display: block;
    }
`;

const Div = styled.div`
    font-family: 'Inter', sans-serif !important;
    box-sizing: border-box;
    overflow-x: hidden;
    

    @media (max-width: 1000px){
    display: flex;
    justify-content: center;
    }
`;


const Mobile = styled.div`
  width: 350px;
  display: block;
  margin: 0 auto;
  
  @media (max-width: 1000px){
   display: flex;
   padding: 2rem;
   z-index: 2; 
   justify-content: center;
    }
`;

const ControllAll = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 1rem;
`;

const Logop = styled.img`
  max-width: 180px;
  width: 100%;
  height: 180px;
`;

const Nick = styled.h1`
  color: white;
  font-family: 'Inter';
  font-size: 2.5rem;
  margin-top: -10px;
  text-align: center;

  @media (max-width: 1000px){
    font-size: 2rem;
    }

`;

const Nick2 = styled.h1`
  color: white;
  font-family: 'Inter';
  font-size: 1.3rem;
  margin-top: -18px;
  font-weight: 400;
  text-align: center;
`;

const Link2 = styled.a`
  width: 100%;
  margin: 0.5rem;
  padding: 0.8rem;
  cursor: pointer;
  background: white;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  text-decoration: none;
  position: relative;
  color: black;

  @media (max-width: 1000px){
   width: 300px;
    }
`;

const LinkName = styled.p`
  font-family: 'Inter';
  font-size: 1rem;
  font-weight: 600;
  margin-right: 16px;
`;

const LinkIcon = styled.img`
  width: 26px;
  position: absolute;
  right: 10%;
  top: 30%;
  transform: translate(-50,-50);
`;

const Copyright = styled.p`
  text-align: center;
  color: #c3c3c3;
`;

const Voltar = styled.div`
  width: 100%;
  padding: 1rem;
  margin: 1rem;
  display: flex;
  justify-content: left;
`;

const Sair = styled.img`
  width: 30px;
  margin-left: 1rem;
`;

function Links() {
  return (
    <Div>
      <Background src={BackgroundF}/> 
      <Mobile>
         <ControllAll>
            <Voltar>
                <StyledLink alt="Sair" to="/">
                      <Sair src={Back} alt='Sair' title='Sair'/>
                </StyledLink>              
            </Voltar>
            <Logop src={Logo}/>
            <Nick>Espiritismo Raiz</Nick>
            <Nick2>Maior Canal Espírita do Mundo</Nick2>

              <Link2 target="_blank" href='https://www.instagram.com/esabbag/'>
                  <LinkName>INSTA EDU</LinkName>
                  <LinkIcon src={Instagram}/>
              </Link2>

              <Link2 target="_blank" href='https://www.instagram.com/espiritismo_raiz/'>
                  <LinkName>INSTA ESPIRITISMO RAIZ</LinkName>
                  <LinkIcon src={Instagram}/>
              </Link2>

              <Link2 target="_blank" href='https://www.edusabbag.com/'>
                  <LinkName>INSTITUTO EDU</LinkName>
                  <LinkIcon src={Caridade}/>
              </Link2>

              <Link2 target="_blank" href='https://www.tiktok.com/@espiritismo_raiz'>
                  <LinkName>TIKTOK</LinkName>
                  <LinkIcon src={Tiktok}/>
              </Link2>

              <Link2 target="_blank" href='https://www.facebook.com/eduardoespiritismo'>
                  <LinkName>FACEBOOK</LinkName>
                  <LinkIcon src={Facebook}/>
              </Link2>

              <Link2 target="_blank" href='https://www.youtube.com/@EspiritismoRaiz'>
                  <LinkName>YOUTUBE</LinkName>
                  <LinkIcon src={Youtube2}/>
              </Link2>

              <Link2 target="_blank" href='https://www.youtube.com/@CanaldoEdu'>
                  <LinkName>ESTUDOS</LinkName>
                  <LinkIcon src={Youtube2}/>
              </Link2>

              <Link2 target="_blank" href='https://www.youtube.com/watch?v=fs1VLSHW4lQ&ab_channel=EspiritismoRaiz-EduardoSabbag'>
                  <LinkName>CIRURGIA ESPIRITUAL</LinkName>
                  <LinkIcon src={Youtube2}/>
              </Link2>

              <Link2 target="_blank" href='https://apps.apple.com/us/app/espiritismoraiz-eduardo-sabbag/id6468327741'>
                  <LinkName>NOSSO APP IOS</LinkName>
                  <LinkIcon src={Apple}/>
              </Link2>

              <Link2 target="_blank" href='https://play.google.com/store/apps/details?id=com.app.espiritismoraiz'>
                  <LinkName>NOSSO APP ANDROID</LinkName>
                  <LinkIcon src={Google}/>
              </Link2>

              <Copyright>©2023 Espiritismo Raiz. Todos os direitos reservados.</Copyright>

           </ControllAll>
      </Mobile>
    </Div>
  );
}

export default Links;
