import React from "react";
import styled from 'styled-components';
import Celular from '../Banner/Banner.js';
import { motion } from 'framer-motion';
import Toponame  from "../NameTopo/index.js";
import Menu from "../Menu/Menu.js";
import Footer from "../Footer/Footer.js";

const Container = styled.div`
  background: white;
`;

function Aplicativo() {
    return (
          <Container>
            <Toponame/>
            <Menu />
              <Celular/>
            <Footer/>
          </Container>
    );
  }
  
  export default Aplicativo;

