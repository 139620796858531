import Rotas from './Rotas.js';
import { BrowserRouter } from 'react-router-dom';
import styled from 'styled-components';
import Logo from '../src/assets/LOGO-MOBILE.png';
import Apple from '../src/assets/icon/apple.png';
import Google from '../src/assets/icon/playstore.png';
import Facebook from '../src/assets/icon/facebook.png';
import Tiktok from '../src/assets/icon/tiktok.png';
import Instagram from '../src/assets/icon/instagram.png';
import Youtube2 from '../src/assets/icon/youtube2.png';
import BackgroundF from '../src/assets/Black6.jpg';
import '../src/Global.css';


const Background = styled.img`
  display: none;
  position: absolute;
  width: 100vw;
  height: 140vh;
  z-index: -2;

  
  @media (max-width: 1000px){
   display: block;
    }
`;

const Div = styled.div`
    font-family: 'Inter', sans-serif !important;
    box-sizing: border-box;
    overflow-x: hidden;
    

    @media (max-width: 1000px){
    display: flex;
    justify-content: center;
    }
`;

const Desktop = styled.div`
  display: block;
`;

const Mobile = styled.div`
  display: none;
  
  @media (max-width: 1000px){
   display: flex;
   padding: 2rem;
   z-index: 2; 
   justify-content: center;
    }
`;

const ControllAll = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 1rem;
`;

const Logop = styled.img`
  max-width: 180px;
  width: 100%;
  height: 180px;
`;

const Nick = styled.h1`
  color: white;
  font-family: 'Inter';
  font-size: 1.3rem;
  margin-top: -10px;
`;

const Link = styled.a`
  width: 100%;
  margin: 0.5rem;
  padding: 0.8rem;
  cursor: pointer;
  background: white;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  text-decoration: none;
  transition: 300ms;
  position: relative;

&:hover{
  background: gray;
}  

`;

const LinkName = styled.p`
  color: black;
  font-family: 'Inter';
  font-size: 1rem;
  font-weight: 600;
  margin-right: 16px;
`;

const LinkIcon = styled.img`
  width: 26px;
  position: absolute;
  right: 10%;
  top: 30%;
  transform: translate(-50,-50);
`;

const RedesSociais = styled.div`
  display: flex;
  flex-direction: row;
  margin: 1rem;
`;

const Imagem = styled.img`
  width: 50px;
  height: 50px;
  transition: 300ms;


&:hover{
  filter: brightness(0.8);
}  

`;


const Copyright = styled.p`
  text-align: center;
  color: #c3c3c3;
`;

function App() {
  return (
    <Div>
      <Desktop>
        <BrowserRouter>
          <Rotas />
        </BrowserRouter>
      </Desktop>
    </Div>
  );
}

export default App;
