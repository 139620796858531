import React from "react";
import styled from 'styled-components';
import Banner1 from '../../assets/banner_chama.jpg';
import Banner2 from '../../assets/banner_fundacao.jpg';
import { motion } from 'framer-motion';
import Toponame  from "../NameTopo/index.js";
import Menu from "../Menu/Menu.js";
import Footer from "../Footer/Footer.js";

const Container = styled.div`
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 1rem;
  background: white;
`;

const ControllText = styled.div`
  width: 100%;
  max-width: 800px;
  `;

const Title = styled.h1`
  font-size: 3rem;
  font-weight: 600;
`;

const CardControll = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: row;

  @media (max-width: 1000px){
    flex-direction: column;
    }
`;

const Card = styled.div`
  margin: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1.5rem;
  border-radius: 10px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  transition: 300ms;

  &:hover{
    transform: scale(1.1);
  }

  a{
    width: 100%;
  }
`;

const CardImg = styled.img`
  width: 300px;
  height: 300px;
  border-radius: 10px;
`;

const CardTitle = styled.h1`
  font-size: 1.5rem;
`;


const CardButton = styled.button`
  margin-top: 0.5rem;
  width: 100%;
  padding: 1rem;
  border-radius: 10px;
  border: none;
  cursor: pointer;
  background: black;
  color: white;
  font-size: 1rem;
  font-weight: 600;
  transition: 500ms;

&:hover{
  background: gray;
}

`;

function Caridade() {
    return (
      <div>
      <Toponame/>
      <Menu />
      <Container>
        <ControllText>
          <Title>Caridade</Title>
        </ControllText>
          <CardControll>
              <Card>
                <CardImg src={Banner2}/>
                <CardTitle>Fundação Sabbag</CardTitle>
                <a target="_blank" href="https://www.edusabbag.com/plans-pricing"><CardButton>Clique aqui!</CardButton></a>
              </Card>
          </CardControll>
      </Container>
      <Footer/>
      </div>
    );
  }
  
  export default Caridade;

