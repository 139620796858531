import React from 'react';
import { Routes, Route } from "react-router-dom";
import Home from '../src/components/Home/Home.js';
import Energias from './components/Energias/Energias.js';
import Notificacao from './components/Notificacao/Notificacao.js';
import Caridade from './components/Caridade/Caridade.js';
import Aplicativo from './components/Aplicativo/Aplicativo.js';
import Sobre from './components/Sobre/Sobre.js';
import Politica from './components/Sobre/Termos.js';
import Admin from '../src/components/Admin/Admin.js';
import Link from './Links.js';
import Play from './Play.js';
import { AnimatePresence } from "framer-motion";



function App() {
  return (
    <div>
        <AnimatePresence mode="wait">  
        <Routes>
            <Route path="/" element={<Home/>} />
            <Route path="/energias" element={<Energias/>} />
            <Route path="/notificacao" element={<Notificacao/>} />
            <Route path="/caridade" element={<Caridade/>} />
            <Route path="/aplicativo" element={<Aplicativo/>} />
            <Route path="/sobre" element={<Sobre/>} />
            <Route path="/link" element={<Link/>} />
            <Route path="/play" element={<Play/>} />
            <Route path="/admin" element={<Admin/>} />
            <Route path="/politica" element={<Politica/>} />
        </Routes>
        </AnimatePresence>  
    </div>
  );
}

export default App;
