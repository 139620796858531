import React, { useState } from "react";
import styled from 'styled-components';
import Toponame  from "../NameTopo/index.js";
import Menu from "../Menu/Menu.js";
import Footer from "../Footer/Footer.js";


const Container = styled.div`
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background: white;
`;

const ControlText = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: 1rem;
`;

const Title = styled.h1``;

const SubTitle = styled.p`
  width: 100%;
  max-width: 444px;
  color: gray;
  font-size: 1.3rem;
  font-weight: 400;
  margin: -10px;
  text-align: center;
`;

const ControlInput = styled.div`
  width: 100%;
  max-width: 400px;
  display: flex;
  flex-direction: column;
  margin: 0.2rem;
`;

const NameInput = styled.h1`
  font-size: 1rem;
`;

const Campo = styled.input`
  padding: 1rem;
  height: 30px;
  font-size: 1rem;
  font-weight: 600;
  border-radius: 4px;
`;

const Botao = styled.button`
  width: 300px;
  padding: 1rem;
  font-size: 1rem;
  font-weight: 600;
  border: none;
  border-radius: 4px;
  margin-top: 1rem;
  margin-bottom: 1rem;
  transition: 300ms;
  cursor: pointer;
  background: black;
  color: white;

  &.botao-desabilitado {
    cursor: not-allowed;
    background: #ccc;
    color: #666;
  }

  &:hover{
  background: gray;
}
`;

const Aviso = styled.p`
  text-align: center;
  color: black;
  font-family: 'Inter';
  font-size: 18px;
`;

const Erro = styled.p`
  text-align: center;
  color: red;
  font-family: 'Inter';
  font-size: 18px;
`;

const ControlDe = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const Titulo = styled.h1`
  color: black;
  font-family: 'Inter';
  font-size: 44px;
  text-align: center;
  font-weight: 600;
  margin: 10px;
`;


const Descri = styled.p`
  width: 400px;
  color: gray;
  font-family: 'Inter';
  font-size: 1.3rem;
  font-weight: 400;
  text-align: center;
  margin-top: -4px;
`;



function Energias() {
  const [nome, setNome] = useState("");
  const [cidade, setCidade] = useState("");
  const [enviando, setEnviando] = useState(false);
  const [bloquearBotao, setBloquearBotao] = useState(false);
  const [mostrarMensagem, setMostrarMensagem] = useState(false);
  const [mensagemErro, setMensagemErro] = useState("");

  const handleEnviar = async () => {
    if (enviando || bloquearBotao || !nome || !cidade) {
      setMensagemErro("Por favor, preencha todos os campos.");

      setTimeout(() => {
        setMensagemErro(""); // Limpa a mensagem de erro após 3 segundos
      }, 3000);
      return;
    }

    setEnviando(true);
    setBloquearBotao(true);

    const response = await fetch('https://xedjwbcidbilzocsnpwu.supabase.co/rest/v1/Task', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'apikey': 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6InhlZGp3YmNpZGJpbHpvY3NucHd1Iiwicm9sZSI6ImFub24iLCJpYXQiOjE2OTI2NTc0NDIsImV4cCI6MjAwODIzMzQ0Mn0.NtLFPPfNAs6zcdRdyzhqpM5GsjqCISES1UgpCrGJJvg'
      },
      body: JSON.stringify({
        nome: nome,
        cidade: cidade
      })
    });

    if (response.ok) {
      setMostrarMensagem(true);
      setTimeout(() => {
        setMostrarMensagem(false);
        setEnviando(false);
        setBloquearBotao(false);
        setNome('');
        setCidade('');
      }, 3000);
    } else {
      alert('Erro ao enviar os dados. Por favor, tente novamente.');
      setEnviando(false);
      setBloquearBotao(false);
    }
  }

  return (
    <div>
       <Toponame/>
      <Menu />
      <Container>
        <ControlDe>
          <Titulo>Energias</Titulo>
          <Descri>Receba as boas energias dos bons espíritos e ilumine sua jornada.</Descri>
        </ControlDe>
        <ControlInput>
          <NameInput>Nome:</NameInput>
          <Campo
            placeholder="Lucas"
            value={nome}
            onChange={(e) => setNome(e.target.value)}
            required
          />
        </ControlInput>
        <ControlInput>
          <NameInput>Cidade:</NameInput>
          <Campo
            placeholder="Curitiba - PR"
            value={cidade}
            onChange={(e) => setCidade(e.target.value)}
            required
          />
        </ControlInput>
        <Botao
          onClick={handleEnviar}
          disabled={enviando || bloquearBotao}
          className={bloquearBotao ? 'botao-desabilitado' : ''}
        >
          Enviar
        </Botao>
        {mensagemErro && <Erro>Por favor, preencha todos os campos.</Erro>}
        {mostrarMensagem && <Aviso>Enviado com sucesso!</Aviso>}
      </Container>
      <Footer/>
      </div>
  );
}

export default Energias;


