import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import PlayIcon from '../src/assets/play.png';
import PauseIcon from '../src/assets/pause.png';
import AvancarIcon from '../src/assets/avancar.png';
import VoltarIcon from '../src/assets/voltar.png';
import Setaup from '../src/assets/seta_cima.png';
import Setadown from '../src/assets/seta_baixo.png';
import music from './music-api.js'; // Importe o array 'music' aqui
import Sair from '../src/assets/back.png';
import EstrelaIcon from '../src/assets/estrela.png';


const Body = styled.div`
  width: 100vw;
  background: #141414;
  box-sizing: border-box;
  touch-action: manipulation;
  @viewport {
    width: device-width;
    zoom: 1.0;
    min-zoom: 1.0;
    max-zoom: 1.0;
  }
  @-ms-viewport {
    width: device-width;
    zoom: 1.0;
    min-zoom: 1.0;
    max-zoom: 1.0;
  }
  -ms-content-zooming: none;
`;


const Input = styled.input`
  display: none;
  width: 300px;
  height: 40px;
  border-radius: 10px;
  border: #333333 solid 1px;
  padding: 8px;
  margin-bottom: 1rem;
  background: #262626;
  color: white;
`;

const Capa = styled.img`
  width: 80px;
  margin-right: 10px;
`;

const Lista = styled.h1`
  font-family: 'Inter';
  font-size: 3rem;
  font-weight: 600;
  color: white;
`;

const Lista2 = styled.h3`
  font-family: 'Inter';
  font-size: 1.3rem;
  font-weight: 600;
  margin-top: -14px;
  color: #c3c3c3;
`;

const ListControl = styled.div`
  display: flex;
  justify-content: center;
  align-items: left;
  flex-direction: column;
`;

const ListTitle = styled.p`
  color: white;
  font-size: 14px;
  font-weight: 600;
  font-family: 'Inter';
  text-align: left;
`;

const ListSub = styled.p`
  font-family: 'Inter';
  color: #c3c3c3;
  margin-top: -10px;
  text-align: left;
`;


const Estrela = styled.img`
  width: 100px;
  margin-top: -5px;
`;

const BotaoSair = styled.img`
  position: absolute;
  left: 14%;
  top: 4%;
  transform: translate(-50,-50);
`;

const MusicListContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 2rem;
`;

const MusicListItem = styled.button`
  margin: 0.5rem;
  width: 300px;
  padding: 1rem;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  background: #212121;
  border: none;
  border-radius: 1rem;
`;

const PlayerContainer = styled.div`
  background: #141414;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 1rem;
  position: relative;
  margin-top: 1rem;
`;

const NamePage = styled.p`
  color: white;
  font-weight: 600;
  margin-top: 4rem;
  margin-bottom: 2rem;
`;

const ControleAlbum = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 1rem;
`;

const AlbumArt = styled.img`
  z-index: 2;

/* Ajuste conforme necessário */
`;

const AlbumArt2 = styled.img`
  filter: blur(45px); /* Adiciona o efeito de blur */
  z-index: 1;
  position: absolute;
  left: 0;
  bottom: 0; /* Ajuste conforme necessário */
`;


const Controls = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ControlButton = styled.button`
  border: none;
  background: none;
`;

const BotoesSkip = styled.img`
  margin: 6px;
  border: none;
  cursor: pointer;
`;

const BotoesPlayAndPause = styled.img`
  cursor: pointer;    
`;

const ControlText = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 1rem;
`;

const Title = styled.h1`
  font-size: 22px;
  font-weight: 600;
  color: white;
  margin-bottom: -4px;
`;

const Subtitle = styled.p`
  font-size: 18px;
  font-weight: 300;
  color: white;
`;

const ControlProgress = styled.div``;

const ProgressBar = styled.div`
  width: 268px;
  height: 6px;
  background-color: #545454;
  margin-top: 20px;
  margin-bottom: 10px;
  position: relative;
  border-radius: 14px;
`;

const Progress = styled.div`
  height: 100%;
  background-color: #732FFC;
  width: ${props => props.progress}%;
`;

const ControleDisplayTime = styled.div`
   width: 268px;
   display: flex;
   justify-content: space-between;
`;

const TimeDisplay1 = styled.div`
  color: #545454;
  margin-top: 10px;
  margin-bottom: 40px;
  font-size: 16px;
  font-weight: 500;
`;

const TimeDisplay2 = styled.div`
  color: white;
  margin-top: 10px;
  margin-bottom: 40px;
  font-size: 16px;
  font-weight: 500;
`;

const LeituraControl = styled.div`
   width: 100%;
   text-align: center;
   margin-top: 3rem;
`;

const TitleLeitura = styled.p`
     font-size: 16px;
     color: white;
     font-weight: 600;
`;

const SetaIcon = styled.img``;

const TextLeitura = styled.p`
   color: white;
   padding: 2rem;
`;

const Play = () => {
  const [isPlaying, setIsPlaying] = useState(false);
  const [currentTrack, setCurrentTrack] = useState(0);
  const [progress, setProgress] = useState(0);
  const [currentTime, setCurrentTime] = useState('00:00');
  const [duration, setDuration] = useState('00:00');
  const audioRef = useRef(new Audio(music[currentTrack].audio));
  const [isTextoVisivel, setIsTextoVisivel] = useState(false);
  const [isPlayerVisible, setIsPlayerVisible] = useState(false);
  const [showExitButton, setShowExitButton] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const filteredMusic = music.filter((track) => {
    return (
      track.titulo.toLowerCase().includes(searchTerm.toLowerCase()) ||
      track.subtitulo.toLowerCase().includes(searchTerm.toLowerCase())
    );
  });

  const toggleTextoVisivel = () => {
      setIsTextoVisivel(!isTextoVisivel);
    };
    


  const formatTime = (timeInSeconds) => {
    const minutes = Math.floor(timeInSeconds / 60);
    const seconds = Math.floor(timeInSeconds % 60);

    return `${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
  };

  const togglePlay = () => {
    if (isPlaying) {
      audioRef.current.pause();
    } else {
      if (audioRef.current.readyState >= 2) {
        audioRef.current.play();
      } else {
        audioRef.current.addEventListener('loadeddata', () => {
          audioRef.current.play();
        });
      }
    }
    setIsPlaying(!isPlaying);
  };

  const playNextTrack = () => {
    if (currentTrack < music.length - 1) {
      audioRef.current.pause();
      setCurrentTrack(currentTrack + 1);
  
      audioRef.current.src = music[currentTrack + 1].audio;
      audioRef.current.load(); // Carrega a nova faixa de áudio
      audioRef.current.addEventListener('canplaythrough', () => {
        setIsPlaying(true);
        audioRef.current.play();
      });
    }
  };
  
  const playPreviousTrack = () => {
    if (currentTrack > 0) {
      audioRef.current.pause();
      setCurrentTrack(currentTrack - 1);
  
      audioRef.current.src = music[currentTrack - 1].audio;
      audioRef.current.load(); // Carrega a nova faixa de áudio
      audioRef.current.addEventListener('canplaythrough', () => {
        setIsPlaying(true);
        audioRef.current.play();
      });
    }
  };

  const handleTimeUpdate = () => {
    const currentTime = audioRef.current.currentTime;
    const duration = audioRef.current.duration;

    setProgress((currentTime / duration) * 100);
    setCurrentTime(formatTime(currentTime));
    setDuration(formatTime(duration));
  };

  useEffect(() => {
    audioRef.current.addEventListener('loadeddata', () => {
      if (isPlaying) {
        audioRef.current.play();
      }
    });

    audioRef.current.addEventListener('timeupdate', handleTimeUpdate);

    return () => {
      audioRef.current.removeEventListener('loadeddata', () => {
        if (isPlaying) {
          audioRef.current.play();
        }
      });

      audioRef.current.removeEventListener('timeupdate', handleTimeUpdate);
    };
  }, [currentTrack, isPlaying]);

  const handleProgressBarClick = (e) => {
      const progressBar = e.currentTarget;
      const clickPosition = e.clientX - progressBar.offsetLeft;
      const progressBarWidth = progressBar.offsetWidth;
      const newProgress = (clickPosition / progressBarWidth) * 100;
      const newTime = (newProgress / 100) * audioRef.current.duration;
      
      audioRef.current.currentTime = newTime;
      setProgress(newProgress);
    };
  
    const toggleExitButton = () => {
      setShowExitButton(!showExitButton);
    };
  
    const handleMusicSelection = (index) => {
      if (window.ReactNativeWebView) {
        window.ReactNativeWebView.postMessage('playerAberto');
      }
      setCurrentTrack(index);
      setIsPlaying(true);
      audioRef.current.src = music[index].audio;
      audioRef.current.play();
      setIsPlayerVisible(true); 
      setShowExitButton(true); // Mostra o botão de sair quando uma música é selecionada
    };
    
  
    const handleExitButtonClick = () => {
      if (window.ReactNativeWebView) {
        window.ReactNativeWebView.postMessage('playerFechado');
      }
    
      setIsPlayerVisible(false);
      setShowExitButton(false);
      setIsTextoVisivel(false);
      audioRef.current.pause();
    };
    

    
    return (
      <Body>
      {isPlayerVisible && ( // Renderiza o PlayerContainer somente se isPlayerVisible for true
        <PlayerContainer>
          {showExitButton && <BotaoSair src={Sair} onClick={handleExitButtonClick} />}
          <NamePage>Espiritismo Raiz</NamePage>
          <ControleAlbum>
              <AlbumArt src={music[currentTrack].album} alt="Capa do álbum" />
              <AlbumArt2 src={music[currentTrack].album} alt="Capa do álbum" />
          </ControleAlbum>
          <ControlText>
            <Title>{music[currentTrack].titulo}</Title>
            <Subtitle>{music[currentTrack].subtitulo}</Subtitle>
          </ControlText>
  
          <ControlProgress>
            <ProgressBar onClick={handleProgressBarClick}>
              <Progress progress={progress} />
            </ProgressBar>
            <ControleDisplayTime>
              <TimeDisplay1>{currentTime}</TimeDisplay1>
              <TimeDisplay2>{duration}</TimeDisplay2>
            </ControleDisplayTime>
          </ControlProgress>
  
          <Controls>
            <ControlButton>
              <BotoesSkip src={VoltarIcon} alt="Anterior" onClick={playPreviousTrack} />
            </ControlButton>
            <ControlButton>
              <BotoesPlayAndPause
                src={isPlaying ? PauseIcon : PlayIcon}
                alt={isPlaying ? 'Pause' : 'Play'}
                onClick={togglePlay}
              />
            </ControlButton>
            <ControlButton>
              <BotoesSkip src={AvancarIcon} alt="Próximo" onClick={playNextTrack} />
            </ControlButton>
          </Controls>
          <LeituraControl>
            <SetaIcon src={isTextoVisivel ? Setadown : Setaup} onClick={toggleTextoVisivel} />
            <TitleLeitura>Leitura</TitleLeitura>
            {isTextoVisivel && <TextLeitura>{music[currentTrack].trecho}</TextLeitura>}
          </LeituraControl>
        </PlayerContainer>
          )}

        {!isPlayerVisible && (
          <MusicListContainer>
            <Lista>Evangelho</Lista>
            <Lista2>Espiritismo Raiz</Lista2>
            <Input
              type="text"
              placeholder="Pesquisar..."
              value={searchTerm}
              onChange={handleSearchChange}
            />
            {filteredMusic.map((track, index) => (
            <MusicListItem key={index} onClick={() => handleMusicSelection(index)}>
                <Capa src={track.album}/>
                <ListControl>
                  <ListTitle>{track.titulo}</ListTitle>
                  <ListSub> {track.subtitulo}</ListSub>
                  <Estrela src={EstrelaIcon}/>
                </ListControl> 
              </MusicListItem>
            ))}
          </MusicListContainer>
        )}   
      </Body>
    );
  };
  
  export default Play;
