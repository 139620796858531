import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import ReactModal from 'react-modal';


const Divinopolis = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const Control = styled.div`
  width: 500px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin: 20px auto;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  padding: 2rem;
  background: white;

  @media (max-width: 1000px){
    width: 250px;
    }
`;

const CustomModal = styled(ReactModal)`
  /* Estiliza o overlay (fundo escuro) do modal */
  opacity: 1; /* Inicia com a opacidade zero para uma animação de fade in */
  transition: opacity 300ms ease-in-out;

  &.ReactModal__Overlay--after-open {
    opacity: 1; /* Torna o overlay totalmente visível */
  }

  &.ReactModal__Overlay--before-close {
    opacity: 0; /* Torna o overlay invisível antes do fechamento */
  }
`;

const ModalContent = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  border-radius: 8px;
  padding: 20px;
  max-width: 80%;
  max-height: 80%;
  overflow-y: auto;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);

  @media (max-width: 1000px){
    width: 250px;
    }
`;


const StyledButton = styled.button`
  background-color: #007bff;
  color: #fff;
  font-size: 16px;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 4px;
  margin-right: 10px;
  transition: 300ms;

  &:disabled {
    cursor: not-allowed;
    opacity: 0.6; /* Ajuste a opacidade conforme desejado */
  }

  &:hover{
    filter: brightness(0.8);
  }

  @media (max-width: 1000px){
    margin-bottom: 1rem;
    }
`;

const StyledButton3 = styled.button`
  background-color: #007bff;
  color: #fff;
  font-size: 16px;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 4px;
  margin-right: 10px;
  transition: 300ms;

  &:disabled {
    cursor: not-allowed;
    opacity: 0.6; /* Ajuste a opacidade conforme desejado */
  }

  &:hover{
    filter: brightness(0.8);
  }
`;


const StyledButton2 = styled.button`
  background-color: red;
  color: #fff;
  font-size: 16px;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 4px;
  margin-right: 10px;
  transition: 300ms;

  &:hover{
    filter: brightness(0.8);
  }
`;

const StyledInput = styled.input`
  padding: 10px;
  font-size: 16px;
  border-radius: 4px;
  border: 1px solid #ccc;
  margin-bottom: 10px;
`;

const Title = styled.h1`
  color: black;
`;

const App = () => {
  const [titulo, setTitulo] = useState(''); // Define o estado para o título
  const [mensagem, setMensagem] = useState(''); // Define o estado para a mensagem
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [formularioCompleto, setFormularioCompleto] = useState(false);
  const [enviando, setEnviando] = useState(false);
  const [mostrarConfirmacao, setMostrarConfirmacao] = useState(false);
  const [senha, setSenha] = useState('');
  const [senhaCorreta, setSenhaCorreta] = useState(false);
  const senhaCorretaValor = 'Notific123@';


  const verificarSenha = () => {
    if (senha === senhaCorretaValor) {
      setSenhaCorreta(true);
    } else {
      alert('Senha incorreta. Tente novamente.');
    }
  };

  const TrocarName = async () => {
    try {
      // Atualizar o registro no Supabase
      const supabaseResposta = await fetch('https://xedjwbcidbilzocsnpwu.supabase.co/rest/v1/Espiritismo?id=eq.7', {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
          'apikey': 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6InhlZGp3YmNpZGJpbHpvY3NucHd1Iiwicm9sZSI6ImFub24iLCJpYXQiOjE2OTI2NTc0NDIsImV4cCI6MjAwODIzMzQ0Mn0.NtLFPPfNAs6zcdRdyzhqpM5GsjqCISES1UgpCrGJJvg' // Substitua pelo seu API Key do Supabase
        },
        body: JSON.stringify({ titulo, mensagem })
      });
  
      const supabaseDados = await supabaseResposta.json();
      console.log('Resposta do Supabase:', supabaseDados);

      setTitulo('');
      setMensagem('');
      
    } catch (error) {
      console.error('Erro ao ativar o Node.js:', error);
    }
  };


  const ativarNode = async () => {
    try {
      setEnviando(true); // Desativa o botão
      const resposta = await fetch('http://localhost:8000/ativar-node', {
        method: 'POST'
      });
  
      const dados = await resposta.json();
      console.log(dados);
  
      setMostrarConfirmacao(true); // Exibe a mensagem de confirmação
  
      setTimeout(() => {
        setMostrarConfirmacao(false); // Esconde a mensagem de confirmação após 3 segundos
        setEnviando(false); // Reativa o botão
      }, 3000);
    } catch (error) {
      console.error('Erro ao ativar o Node.js:', error);
      setEnviando(false); // Em caso de erro, reativa o botão imediatamente
    }
    closeModal();
    setTitulo('');
    setMensagem('');
  };
  

  
  useEffect(() => {
    setFormularioCompleto(!!titulo && !!mensagem);
  }, [titulo, mensagem]);

  const openModal = async () => {
    try {
      await TrocarName();
      setModalIsOpen(true);
    } catch (error) {
      console.error('Erro ao abrir o modal:', error);
    }
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  return (
    <div>
    {!senhaCorreta ? (
      <Divinopolis>
        <h1>Insira a senha:</h1>
        <StyledInput
          type="password"
          value={senha}
          onChange={(e) => setSenha(e.target.value)}
          placeholder="Senha"
        />
        <StyledButton onClick={verificarSenha}>Entrar</StyledButton>
      </Divinopolis>
    ) : (


    <div>
      <Control>
        <Title>Enviar Notificações Espiritismo Raiz</Title>
        <StyledInput
          type="text"
          placeholder="Título"
          value={titulo}
          onChange={(e) => {
            setTitulo(e.target.value);
            setFormularioCompleto(!!e.target.value && !!mensagem);
          }}
        />

        <StyledInput
          type="text"
          placeholder="Mensagem"
          value={mensagem}
          onChange={(e) => {
            setMensagem(e.target.value);
            setFormularioCompleto(!!titulo && !!e.target.value);
          }}
        />
        <StyledButton onClick={openModal} disabled={!formularioCompleto}>
          Enviar
        </StyledButton>
      </Control>

      <div>
        <CustomModal
          isOpen={modalIsOpen}
          onRequestClose={closeModal}
          contentLabel="Exemplo de Modal"
        >
          <ModalContent>
            <h2>Enviar Notificação</h2>
            <StyledButton3 onClick={ativarNode} disabled={enviando}>
              Enviar para todos celulares!
            </StyledButton3>


            <StyledButton2 onClick={closeModal}>Fechar</StyledButton2>
      

          </ModalContent>
        </CustomModal>
      </div>
    </div>
     )}
     </div>
  );
};

export default App;
