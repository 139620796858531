import React from "react";
import styled from 'styled-components';
import Logo from '../../assets/logo.png';
import { Link } from 'react-router-dom';

const Container = styled.div`
  text-align: center;
  padding: 0.3rem;
  background: black;
`;

const Nav = styled.nav`
    display: block;
    max-width: 1100px;
    margin: 0 auto;
    padding: 1rem;
    display: flex;
    justify-content: space-between;

    @media (max-width: 1000px){
    display: none;
    }
`;

const Imagem = styled.img`
    width: 120px;
    height: 120px;

    @media (max-width: 1000px){
    display: none;
    }
`;


const Ul = styled.ul`
    list-style: none;
    display: flex;
    justify-content: center;
    align-items: center;
   

    @media (max-width: 1000px){
      margin-right: 2rem;
      flex-direction: row;
    }
`;


const StyledLink = styled(Link)`
  color: white;
  text-decoration: none;
  font-weight: bold;

  &:hover {
    text-decoration: underline;
  } 
  `;


const Li = styled.li`
    color: white;
    font-weight: 600;
    font-size: 1rem;
    margin: 0rem 1rem 1rem 0rem;

    @media (max-width: 1000px){
      font-size: 0.8rem;
    }
`;

const Nav2 = styled.div`
    display: none;
    max-width: 1100px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;

    @media (max-width: 1000px){
    display: block;
    }
`;

const Imagem2 = styled.img`
    display: none;
    width: 120px;
    height: 120px;

    @media (max-width: 1000px){
    display: block;
    margin: 0 auto;
    }
`;


const Ul2 = styled.ul`
    display: none;

    @media (max-width: 1000px){
      margin-right: 2rem;
      flex-direction: row;
      list-style: none;
       display: flex;
      justify-content: center;
      align-items: center;
   
    }
`;


const StyledLink2 = styled(Link)`
  color: white;
  text-decoration: none;
  font-weight: bold;

  &:hover {
    text-decoration: underline;
  } 
  `;


const Li2 = styled.li`
    display: none;
    color: white;
    font-weight: 600;
    font-size: 1rem;
    margin: 0rem 1rem 1rem 0rem;

    @media (max-width: 1000px){
      font-size: 0.8rem;
      display: block;
    }
`;

function Menu() {
    return (
      <Container>
        <Nav>
        <Link to="/">
            <Imagem src={Logo} alt="Logo" title="Logo"/>
        </Link>
            <Ul>
                <Li><StyledLink alt="Energias" to="/">Início</StyledLink></Li>
                <Li><StyledLink alt="Aplicativo" to="/aplicativo">Aplicativo</StyledLink></Li>
                <Li><StyledLink alt="Energias" to="/energias">Energias</StyledLink></Li>
                <Li><StyledLink alt="Caridade" target="_blank" to="https://www.edusabbag.com/">Caridade</StyledLink></Li>
                <Li><StyledLink alt="Sobre" to="/sobre">Sobre</StyledLink></Li>
            </Ul>
        </Nav>

        <Nav2>
        <Link to="/">
            <Imagem2 src={Logo}/>
        </Link>
            <Ul2>
                <Li2><StyledLink2 alt="aplicativo" to="/aplicativo">Aplicativo</StyledLink2></Li2>
                <Li2><StyledLink2 alt="Energias" to="/energias">Energias</StyledLink2></Li2>
                <Li2><StyledLink2 alt="Caridade" target="_blank" to="https://www.edusabbag.com/">Caridade</StyledLink2></Li2>
                <Li2><StyledLink2 alt="Sobre" to="/sobre">Sobre</StyledLink2></Li2>
            </Ul2>
        </Nav2>
      </Container>
    );
  }
  
  export default Menu;

